<template>
	<v-layout :justify-start="$vuetify.breakpoint.mdAndUp" :justify-center="$vuetify.breakpoint.smAndDown" align-start row wrap>
		<v-flex shrink row>
			<v-layout shrink wrap grid-list-sm :fluid="$vuetify.breakpoint.mdAndUp" :column="$vuetify.breakpoint.smAndDown">
				<v-flex v-for="(entry, index) in content" :key="index" shrink my-1 mx-2 :disabled="true">
					<v-card
						v-if="isAvailable(entry)"
						hover
						tile
						:height="$vuetify.breakpoint.mdAndUp ? '15em' : '10em'"
						:width="$vuetify.breakpoint.mdAndUp ? '20em' : '15em'"
						max-width="90vw"
						class="ma-0 overflow-hidden"
						@click="goTo(entry.routeName)"
					>
						<v-responsive :aspect-ratio="4 / 3" class="justify-center align-center">
							<v-layout v-if="entry.img" :class="$vuetify.breakpoint.mdAndUp ? 'pt-3 px-3' : 'px-3 py-auto my-auto'" justify-center align-center>
								<v-img
									v-if="pictures"
									:src="entry.img"
									:alt="$t('logo')"
									contain
									:height="$vuetify.breakpoint.mdAndUp ? '5em' : '3em'"
									width="auto"
									max-width="100%"
									max-height="100%"
								/>
								<v-icon v-else :size="$vuetify.breakpoint.mdAndUp ? '6em' : '5em'" color="primary">{{ entry.img }}</v-icon>
							</v-layout>
							<v-card-title
								primary-title
								:class="$vuetify.breakpoint.mdAndUp ? 'text-xs-center justify-center py-0' : 'text-xs-center justify-center my-0 py-0'"
							>
								<v-layout column>
									<v-flex
										class="align-center d-flex justify-center overflow-hidden"
										:class="$vuetify.breakpoint.mdAndUp ? 'headline mb-0' : 'body-2 my-0'"
										:style="$vuetify.breakpoint.mdAndUp ? 'height:64px' : 'height:42px'"
										>{{ entry.title }}</v-flex
									>
									<v-flex
										v-if="$vuetify.breakpoint.mdAndUp"
										class="align-start d-flex justify-center overflow-hidden"
										:class="$vuetify.breakpoint.mdAndUp ? '' : 'caption'"
										style="height: 42px"
										>{{ entry.subtitle }}</v-flex
									>
								</v-layout>
							</v-card-title>
						</v-responsive>
					</v-card>
					<v-card
						v-else
						hover
						tile
						:height="$vuetify.breakpoint.mdAndUp ? '15em' : '10em'"
						:width="$vuetify.breakpoint.mdAndUp ? '20em' : '15em'"
						max-width="90vw"
						class="ma-0 overflow-hidden"
					>
						<v-responsive :aspect-ratio="4 / 3" class="justify-center align-center">
							<v-layout :class="$vuetify.breakpoint.mdAndUp ? 'pt-3 px-3' : 'px-3 py-auto my-auto'" justify-center align-center>
								<v-img
									v-if="pictures"
									:src="entry.img"
									:alt="$t('logo')"
									contain
									:height="$vuetify.breakpoint.mdAndUp ? '5em' : '3em'"
									width="auto"
									max-width="100%"
									max-height="100%"
								/>
								<v-icon v-else :size="$vuetify.breakpoint.mdAndUp ? '6em' : '5em'" color="grey">{{ entry.img }}</v-icon>
							</v-layout>
							<v-card-title
								primary-title
								:class="$vuetify.breakpoint.mdAndUp ? 'text-xs-center justify-center py-0' : 'text-xs-center justify-center my-0 py-0'"
							>
								<v-layout column>
									<v-flex
										class="align-center d-flex justify-center overflow-hidden"
										:class="$vuetify.breakpoint.mdAndUp ? 'headline mb-0' : 'body-2 my-0'"
										:style="$vuetify.breakpoint.mdAndUp ? 'height:64px' : 'height:42px'"
										>{{ entry.title }}</v-flex
									>
									<v-flex
										v-if="$vuetify.breakpoint.mdAndUp"
										class="align-start d-flex justify-center overflow-hidden"
										:class="$vuetify.breakpoint.mdAndUp ? '' : 'caption'"
										style="height: 42px"
										>{{ entry.subtitle }}</v-flex
									>
								</v-layout>
							</v-card-title>
						</v-responsive>
					</v-card>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'

export default {
	name: 'CardsList',
	mixins: [AppModuleGuard],
	props: {
		pictures: {
			required: true,
			type: Boolean
		},
		content: {
			required: true,
			type: Array
		}
	},
	methods: {
		goTo: function (routePath) {
			this.appService.goTo(routePath)
		},
		isAvailable: function (entry) {
			let result = true
			if (entry['disabled'] && entry.disabled) {
				result = false
			}
			return result
		}
	}
}
</script>
